/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Modal, Button } from 'react-bootstrap-v5'
import GoogleMapReact from 'google-map-react'

type Props2 = {
  lat: number
  lng: number
  text: string
}
const AnyReactComponent: React.FC<Props2> = ({text}) => (
  /*   <div
    style={{
      color: 'white',
      background: 'grey',
      padding: '15px 10px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {text}
  </div> */
  <i
    className='bi bi-geo-alt-fill'
    style={{
      color: 'red',
      fontSize: '20pt',
    }}
  >
    {text}
  </i>
)
type Props = {
  className: string
}

const states = [
  {clave: 'Aguascalientes', label: 'Aguascalientes'},
  {clave: 'Baja California', label: 'Baja California'},
  {clave: 'Baja California Sur', label: 'Baja California Sur'},
  {clave: 'Chihuahua', label: 'Chihuahua'},
  {clave: 'Chiapas', label: 'Chiapas'},
  {clave: 'Campeche', label: 'Campeche'},
  {clave: 'CDMX', label: 'CDMX'},
  {clave: 'Coahuila', label: 'Coahuila'},
  {clave: 'Colima', label: 'Colima'},
  {clave: 'Durango', label: 'Durango'},
  {clave: 'Guerrero', label: 'Guerrero'},
  {clave: 'Guanajuato', label: 'Guanajuato'},
  {clave: 'Hidalgo', label: 'Hidalgo'},
  {clave: 'Jalisco', label: 'Jalisco'},
  {clave: 'Michoacan', label: 'Michoacan'},
  {clave: 'Estado de México', label: 'Estado de México'},
  {clave: 'Morelos', label: 'Morelos'},
  {clave: 'Nayarit', label: 'Nayarit'},
  {clave: 'Nuevo Leon', label: 'Nuevo Leon'},
  {clave: 'Oaxaca', label: 'Oaxaca'},
  {clave: 'Puebla', label: 'Puebla'},
  {clave: 'Quitana Roo', label: 'Quitana Roo'},
  {clave: 'Querétaro', label: 'Querétaro'},
  {clave: 'Sinaloa', label: 'Sinaloa'},
  {clave: 'San Luis Potosi', label: 'San Luis Potosi'},
  {clave: 'Sonora', label: 'Sonora'},
  {clave: 'Tabasco', label: 'Tabasco'},
  {clave: 'Tlaxcala', label: 'Tlaxcala'},
  {clave: 'Tamaulipas', label: 'Tamaulipas'},
  {clave: 'Veracruz', label: 'Veracruz'},
  {clave: 'Yucatán', label: 'Yuacatan'},
  {clave: 'Zacatecas', label: 'Zacatecas'},
]


const ListsWidget5: React.FC<Props> = ({className}) => {
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [dto, setdto] = useState({
    city: '',
    address: '',
    zip_code: '',
    ext_no: '',
    state: '',
    country: '',
  })

  const propsMap = {
    center: {
      lat: 20.6188619,
      lng: -100.4217548,
    },
    zoom: 11,
  }
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const sKeyMaps = 'AIzaSyCPrs09OAOP1JhUmIUgGCSHqb3VfoHqL88';

  return (
    <div className={`card ${className}`} style={{width: '100%'}}>
      <div className='card-body '>
        <div className='mb-10 fw-bold text-dark'>
          {intl.formatMessage({id: 'LISTSWIDGET5.INSTRUCTIONS'})}{' '}
        </div>
        {/* Nombre */} {/* Descripcion */}
        <div className='row'>
          <div className='d-flex card-spacer '>
            <div
              className='form-group fv-plugins-icon-container has-sucess'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1 '>
                {intl.formatMessage({id: 'LISTSWIDGET5.NAME_BUSINESS'})}
              </label>
              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Nombre'
                /*  value='Carl' */
              />
              {/*  <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE_DESCRIP'})}
              </span> */}
            </div>
            <div
              className='form-group fv-plugins-icon-container has-sucess px-5'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.DESCRIPTION'})}
              </label>
              <textarea
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Descripción'
                /* value='Establecimiento de venta de tacos' */
              />
              {/*   <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.MAIL_DESCRIP'})}
              </span> */}
              <div className='fv-plugins-message-container mb-10'></div>
            </div>
          </div>
        </div>
        {/* Telefono y correo */}
        <div className='row'>
          <div className='d-flex card-spacer '>
            <div
              className='form-group fv-plugins-icon-container has-sucess'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1 '>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE'})}
              </label>
              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Teléfono del cliente'
                /*  value='+61412345678rl' */
              />
              {/*  <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE_DESCRIP'})}
              </span> */}
            </div>
            <div
              className='form-group fv-plugins-icon-container has-sucess px-5'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.MAIL'})}
              </label>
              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Correo del cliente'
              />
              {/*   <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.MAIL_DESCRIP'})}
              </span> */}
              <div className='fv-plugins-message-container mb-10'></div>
            </div>
          </div>
        </div>
        {/* Categoria y pais */}
        <div className='row'>
          <div className='d-flex card-spacer '>
            <div
              className='form-group fv-plugins-icon-container has-sucess'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.CATEGORY'})}
              </label>

              <select
                name='tipo'
                className='form-control form-control-solid form-control-lg fs-7 mb-1'
              >
                <option value=''>{intl.formatMessage({id: 'LISTSWIDGET5.OPTION1'})}</option>

                <option value='AF'> {intl.formatMessage({id: 'LISTSWIDGET5.OPTION2'})}</option>
                <option value='AX'> {intl.formatMessage({id: 'LISTSWIDGET5.OPTION3'})}</option>
                <option value='AL'> {intl.formatMessage({id: 'LISTSWIDGET5.OPTION4'})}</option>
              </select>
              {/*   <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE_DESCRIP'})}
              </span> */}
            </div>
            <div
              className='form-group fv-plugins-icon-container has-sucess px-5'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.COUNTRYSELECT'})}
              </label>

              <select
                name='tipo'
                className='form-control form-control-solid form-control-lg fs-7 mb-1'
                value={dto.country}
              >
                <option value=''>{intl.formatMessage({id: 'LISTSWIDGET5.COUNTRYDEF'})}</option>

                <option value='México'> {intl.formatMessage({id: 'LISTSWIDGET5.COUNTRY1'})}</option>
                <option value='Estados Unidos'>
                  {intl.formatMessage({id: 'LISTSWIDGET5.COUNTRY2'})}
                </option>
              </select>
              {/* <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.MAIL_DESCRIP'})}
              </span> */}
              <div className='fv-plugins-message-container mb-10'></div>
            </div>
          </div>
        </div>
        {/* maps */}
        <div className='row'>
          <div className='col-md-12'>
            <Button
              variant='primary'
              onClick={handleShow}
              style={{width: '100%', marginBottom: '5%'}}
            >
              <i className='bi bi-geo-alt-fill'></i>Buscar con Google maps
            </Button>
          </div>
        </div>
        {/* Estado y ciudad */}
        <div className='row'>
          <div className='d-flex card-spacer '>
            <div
              className='form-group fv-plugins-icon-container has-sucess'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.STATE'})}
              </label>
              <select
                placeholder='Seleccione'
                className='form-control form-control-solid form-control-lg fs-7 mb-1'
                value={dto.state}
              >
                <option value='' selected disabled>
                  Seleccionar
                </option>
                {states.map((item) => {
                  return (
                    <option key={item.clave} value={item.clave}>
                      {item.label}
                    </option>
                  )
                })}
              </select>
              {/*   <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE_DESCRIP'})}
              </span> */}
            </div>
            <div
              className='form-group fv-plugins-icon-container has-sucess px-5'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.CITY'})}
              </label>

              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Ciudad del negocio'
                value={dto.city}
              />
              {/*    <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.MAIL_DESCRIP'})}
              </span> */}
              <div className='fv-plugins-message-container mb-10'></div>
            </div>
          </div>
        </div>
        {/* Direccion */}
        <div className='row'>
          <div className='d-flex card-spacer '>
            <div
              className='form-group fv-plugins-icon-container has-sucess'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.ADDRESS'})}
              </label>

              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Dirección del negocio'
                value={dto.address}
                style={{width: '98%'}}
              />
              {/* <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE_DESCRIP'})}
              </span> */}
            </div>
          </div>
        </div>
        {/* Numero y CP */}
        <div className='row'>
          <div className='d-flex card-spacer '>
            <div
              className='form-group fv-plugins-icon-container has-sucess'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-12 mb-1' style={{textAlign: 'center'}}>
                {intl.formatMessage({id: 'LISTSWIDGET5.ADDRESSNUMBER'})}
              </label>

              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Número'
                value={dto.ext_no}
              />
              {/*  <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.PHONE_DESCRIP'})}
              </span> */}
            </div>
            <div
              className='form-group fv-plugins-icon-container has-sucess px-5'
              style={{width: '100%'}}
            >
              <label className='card-label  fs-7 mb-1'>
                {intl.formatMessage({id: 'LISTSWIDGET5.CP'})}
              </label>

              <input
                type='text'
                className='form-control form-control-solid form-control-lg fs-7 mb-1 '
                name='fname'
                placeholder='Código postal'
                value={dto.zip_code}
              />
              {/* <span className='form-text text-muted'>
                {intl.formatMessage({id: 'LISTSWIDGET5.MAIL_DESCRIP'})}
              </span> */}
              <div className='fv-plugins-message-container mb-10'></div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <a
              style={{float: 'right', width: '100%'}}
              href='#'
              className='btn btn-bg-primary text-white '
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              id='kt_toolbar_primary_button'
            >
              {intl.formatMessage({id: 'LISTSWIDGET5.BTN'})}
            </a>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Encuentra tu dirección</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{height: '70vh', width: '100%'}}>
            <GoogleMapReact
              yesIWantToUseGoogleMapApiInternals
              onClick={(ev) => {
                setLat(ev.lat)
                setLng(ev.lng)
                fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${ev.lat},${ev.lng}&key=${sKeyMaps}`
                  )
                  .then((response) => response.json())
                  .then((response) => {
                    let DTO = {
                      city: '',
                      address: '',
                      zip_code: '',
                      ext_no: '',
                      state: '',
                      country: '',
                    }

                    /*   response.data.results[0].map((value: any, i: number) => {
                      console.log(value)
                      if (value.types[0] === 'administrative_area_level_1') {
                        console.log(value)
                      }
                    }) */
                    let address = ''
                    response.data.results[0].address_components.forEach((element: any) => {
                      console.log(element)
                      if (element.types[0] === 'administrative_area_level_1') {
                        DTO.state = element.long_name
                        /*  setstatesSelect({clave: element.long_name, label: element.long_name}) */
                      }
                      if (element.types[0] === 'postal_code') {
                        DTO.zip_code = element.long_name
                      }
                      if (element.types[0] === 'locality') {
                        DTO.city = element.long_name
                      }
                      if (element.types[0] === 'street_number') {
                        DTO.ext_no = element.long_name
                      }

                      if (element.types[0] === 'route') {
                        address += element.long_name
                        if (
                          element.types[0] === 'political' ||
                          element.types[0] === 'neighborhood'
                        ) {
                          address += ', '
                          address += element.long_name
                        }
                      } else {
                        if (
                          element.types[0] === 'political' ||
                          element.types[0] === 'neighborhood'
                        ) {
                          address += ', '
                          address += element.long_name
                        }
                      }
                      if (element.types[0] === 'country') {
                        DTO.country = element.long_name
                      }
                    })
                    DTO.address = address
                    setdto(DTO)
                    handleClose()
                  })
                  .catch((error) => {})
              }}
              bootstrapURLKeys={{key: sKeyMaps}}
              defaultCenter={propsMap.center}
              defaultZoom={propsMap.zoom}
            >
              {lat && <AnyReactComponent lat={lat} lng={lng} text='' />}
            </GoogleMapReact>
          </div>
        </Modal.Body>
        {/*  <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  )
}

export {ListsWidget5}
