/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'

type Props = {
  className: string
}

const TablesWidget1: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  return (
    <div className='card card-custom card-stretch gutter-b'>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label font-weigth-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'TABLESWIDGET1.TITTLE'})}
          </span>
          <span className='text-muted fw-bold fs-7'>
            {intl.formatMessage({id: 'TABLESWIDGET1.SUBTITTLE'})}
          </span>
        </h3>
        <div className='card-toolbar'>
          <a href='#' className='btn btn-primary font-weight-bolder font-size-sm'>
            <span className='svg-icon svg-icon-md svg-icon-white'></span>
            <span>{intl.formatMessage({id: 'TABLESWIDGET1.ADD_USER'})}</span>
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            className='table table-head-custom table-vertical-center'
            id='kt_advance_table_widget_1'
          >
            {/* begin::Table head */}
            <thead>
              <tr className='text-left'>
                <th className='pl-0 width-20px'>
                  <label className='checkbox checkbox-lg checkbox-inline'>
                    <input type='checkbox' value='1'></input>
                  </label>
                </th>
                {/* no me reconoce espacios */}

                <th className='pr-0 width-50px'>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.CLIENTS'})}
                  </span>
                </th>
                <th className='min-width-150px'>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.STORES'})}
                  </span>
                </th>
                <th className='min-width-150px'>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.PRODUCTS'})}
                  </span>
                </th>
                <th className='pr-0 text-right min-width-150px'>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.ACTIONS'})}
                  </span>
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                {/* tr comienza fila 1*/}
                <td className='pl-0'>
                  <label className='checkbox checkbox-lg checkbox-inline'>
                    <input type='checkbox' value='1'></input>
                  </label>
                </td>
                <td className='pr-0'>
                  <div className='symbol symbol-50 symbol-ligth mt-1'>
                    <span className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                        className='h-75 align-self-end'
                        alt=''
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.CLIENT1'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBCLIENT1'})}
                  </span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder d-block font-size-lg'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.PRODUCT1'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBPRODUCT1'})}
                  </span>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 mr-2'>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {intl.formatMessage({id: 'TABLESWIDGET1.PERCENT1'})}
                      </span>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {' '}
                        {intl.formatMessage({id: 'TABLESWIDGET1.TOTAL'})}
                      </span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{width: '65%'}}
                      ></div>
                    </div>
                  </div>
                  {/* no se visualiza en pantalla*/}
                </td>
                <td className='d-flex pr-0 text-right'>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen019.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen055.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                </td>
                {/* tr termina fila 1*/}
              </tr>
              <tr>
                {/* tr comienza fila 2*/}
                <td className='pl-0'>
                  <label className='checkbox checkbox-lg checkbox-inline'>
                    <input type='checkbox' value='1'></input>
                  </label>
                </td>
                <td className='pr-0'>
                  <div className='symbol symbol-50 symbol-ligth mt-1'>
                    <span className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/avatars/018-girl-9.svg')}
                        className='h-75 align-self-end'
                        alt=''
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.CLIENT2'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBCLIENT2'})}
                  </span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder d-block font-size-lg'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.PRODUCT2'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBPRODUCT2'})}
                  </span>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 mr-2'>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {intl.formatMessage({id: 'TABLESWIDGET1.PERCENT2'})}
                      </span>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {intl.formatMessage({id: 'TABLESWIDGET1.TOTAL_COMPLETE'})}
                      </span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{width: '83%'}}
                      ></div>
                    </div>
                  </div>
                  {/* no se visualiza en pantalla*/}
                </td>
                <td className='pr-0 text-right'>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen019.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen055.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                </td>
                {/* tr termina fila 2*/}
              </tr>
              <tr>
                {/* tr comienza fila 3*/}
                <td className='pl-0'>
                  <label className='checkbox checkbox-lg checkbox-inline'>
                    <input type='checkbox' value='1'></input>
                  </label>
                </td>
                <td className='pr-0'>
                  <div className='symbol symbol-50 symbol-ligth mt-1'>
                    <span className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/avatars/026-boy-10.svg')}
                        className='h-75 align-self-end'
                        alt=''
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.CLIENT3'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBCLIENT3'})}
                  </span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder d-block font-size-lg'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.PRODUCT3'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {' '}
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBPRODUCT3'})}
                  </span>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 mr-2'>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {' '}
                        {intl.formatMessage({id: 'TABLESWIDGET1.PERCENT3'})}
                      </span>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {intl.formatMessage({id: 'TABLESWIDGET1.TOTAL'})}
                      </span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{width: '83%'}}
                      ></div>
                    </div>
                  </div>
                  {/* no se visualiza en pantalla*/}
                </td>
                <td className='pr-0 text-right'>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen019.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen055.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                </td>
                {/* tr termina fila 3*/}
              </tr>
              <tr>
                {/* tr comienza fila 4*/}
                <td className='pl-0'>
                  <label className='checkbox checkbox-lg checkbox-inline'>
                    <input type='checkbox' value='1'></input>
                  </label>
                </td>
                <td className='pr-0'>
                  <div className='symbol symbol-50 symbol-ligth mt-1'>
                    <span className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/avatars/026-boy-10.svg')}
                        className='h-75 align-self-end'
                        alt=''
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.CLIENT4'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBCLIENT4'})}
                  </span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder d-block font-size-lg'>
                    {intl.formatMessage({id: 'TABLESWIDGET1.PRODUCT4'})}
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'>
                    {' '}
                    {intl.formatMessage({id: 'TABLESWIDGET1.SUBPRODUCT4'})}
                  </span>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 mr-2'>
                    <div className='d-flex align-items-center justify-content-between mb-2'>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {intl.formatMessage({id: 'TABLESWIDGET1.PERCENT4'})}
                      </span>
                      <span className='text-muted mr-2 font-size-sm font-weight-bold'>
                        {' '}
                        {intl.formatMessage({id: 'TABLESWIDGET1.TOTAL'})}
                      </span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{width: '71%'}}
                      ></div>
                    </div>
                  </div>
                  {/* no se visualiza en pantalla*/}
                </td>
                <td className='pr-0 text-right'>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen019.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen055.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                  <a href='#' className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-4 me-1'
                    />
                  </a>
                </td>
                {/* tr termina fila 4*/}
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {TablesWidget1}
