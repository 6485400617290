import MockAdapter from 'axios-mock-adapter'
import {UserModel} from '../models/UserModel'
import {
  LOGIN_URL,
  GET_USER_BY_ACCESSTOKEN_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
} from '../redux/AuthCRUD'
import {UsersTableMock} from './usersTableMock'

export function mockAuth(mock: MockAdapter) {
  mock.onPost(LOGIN_URL).reply(({data}) => {
    const {email, password} = JSON.parse(data)
    if (email && password) {
      const user = UsersTableMock.table[0]

      if (user) {
        return [
          201,
          {
            accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
            refreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
            user,
          },
        ]
      }
    }

    return [400]
  })

  mock.onPost(REGISTER_URL).reply(({data}) => {
    const {email, full_name} = JSON.parse(data)

    if (email && full_name) {
      const user: UserModel = {
        _id: generateUserId(),
        email,
        full_name,
        auth: {
          accessToken: 'access-token-' + Math.random(),
          refreshToken: 'access-token-' + Math.random(),
        },
        rol: 'admin',
        rol_name: 'Administrator',
        language: 'en',
        rol_id: 'kalsduihas97812',
        push_id: 'kujhasdkuhasdk',
        status: 1,
        __v: 0,
        created_at: '',
        updated_at: '',
      }

      UsersTableMock.table.push(user)
      const auth = user.auth

      return [200, {...auth, password: undefined}]
    }

    return [400]
  })

  mock.onPost(REQUEST_PASSWORD_URL).reply(({data}) => {
    const {email} = JSON.parse(data)

    if (email) {
      const user = UsersTableMock.table.find((x) => x.email.toLowerCase() === email.toLowerCase())
      let result = false
      if (user) {
        result = true
        return [200, {result, password: undefined}]
      }
    }

    return [400]
  })

  mock.onGet(GET_USER_BY_ACCESSTOKEN_URL).reply(({headers: {Authorization}}) => {
    const accessToken =
      Authorization && Authorization.startsWith('Bearer ') && Authorization.slice('Bearer '.length)

    if (accessToken) {
      const user = UsersTableMock.table.find((x) => x.auth?.accessToken === accessToken)

      if (user) {
        return [200, {...user, password: undefined}]
      }
    }

    return [401]
  })

  function generateUserId(): string {
    return UsersTableMock.table[0]._id
  }
}
