import clsx from 'clsx'
import { FC } from 'react'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const intl = useIntl()
  const auth = useSelector((state: RootState) => state.auth)
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
        {/* Activities */}
        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          {/* begin::Drawer toggle */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div> */}
          <div className='topbar-item'>
            <div
              className={clsx(
                'btn btn-icon btn-hover-transparent-white d-flex align-item-center btn-lg px-md-2 w-md-auto'
              )}
              id='kt_quick_user_toggle'
            >
              <span className=' text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1'>
                {intl.formatMessage({id: 'TOPBAR.GREETING'})}
              </span>
              <span className=' text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4'>
                {auth.user?.full_name}
              </span>
            </div>
          </div>
          {/* end::Drawer toggle */}
        </div>
        {/* Quick links */}

        {/* NOTIFICATIONS */}
        <div className={clsx('topbar-item', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {' '}
            <span className=' symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30'>
              Info
            </span>
            <KTSVG path='' className={toolbarButtonIconSizeClass} />
          </div>
          <HeaderUserMenu />
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <HeaderUserMenu />
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
