import {UserModel} from '../models/UserModel'

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      _id: 'lksadd978123asdas',
      full_name: 'Pedro Antonio',
      email: 'admin@demo.com',
      rol: 'admin',
      rol_name: 'Administrator',
      language: 'en',
      rol_id: 'kalsduihas97812',
      push_id: 'kujhasdkuhasdk',
      status: 1,
      __v: 0,
      created_at: '',
      updated_at: '',
    },
    {
      _id: 'lksadd978123asdas',
      full_name: 'Diana Liz',
      email: 'dliz@demo.com',
      rol: 'admin',
      rol_name: 'Administrator',
      language: 'en',
      rol_id: 'kalsduihas97812',
      push_id: 'kujhasdkuhasdk',
      status: 1,
      __v: 0,
      created_at: '',
      updated_at: '',
    },
    {
      _id: 'liasd8asl132s',
      full_name: 'Diana Liz',
      email: 'dliz@demo.com',
      rol: 'admin',
      rol_name: 'Administrator',
      language: 'en',
      rol_id: 'kalsduihas97812',
      push_id: 'kujhasdkuhasdk',
      status: 1,
      __v: 0,
      created_at: '',
      updated_at: '',
    },
  ]
}
