/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLayout} from '../../../../_metronic/layout/core'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('El formato del correo es incorrecto')
    .min(3, 'Al menos debe contener 6 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('Correo requerido'),
  password: Yup.string()
    .min(3, 'caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('Contraseña requerido'),
})

const initialValues = {
  email: 'ruddy.garcia@obsidiansoft.io',
  password: 'Chango123!',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const {setLayout} = useLayout()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [updatedLayout, setUpdatedLayout] = useState(false)
  const [isErrorLogin, setIsErrorLogin] = useState(false)
  const dispatch = useDispatch()

  const onLoginFail = (setStatus: (val: string) => void, setSubmitting: (val: boolean) => void) => {
    setLoading(false)
    setSubmitting(false)
    setStatus('No fue posible iniciar sesion con las credenciales recibidas')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then((res) => {
            if (res.status === 201) {
              const {accessToken, user} = res.data
              dispatch(auth.actions.login(accessToken, user))
              setLoading(false)
              return
            }
            onLoginFail(setStatus, setSubmitting)
          })
          .catch((e) => {
            console.log(e)
            onLoginFail(setStatus, setSubmitting)
            setIsErrorLogin(true)
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (!updatedLayout) {
      setLayout({isAuthPage: true})
      setUpdatedLayout(true)
    }
  }, [setLayout, updatedLayout, setUpdatedLayout])

  return (
    <>
      {/* contenedor izq*/}
      <div className='bg-primary login-aside d-flex flex-column flex-row-auto'>
        {/* contenedor imagen tum me volvio a poner pantalla cortada*/}
        <div className='d-flex flex-column-auto flex-column pt-lg-40 pt-15'>
          <a href='#' className='text-center mb-10'>
            <img
              className='max-h-100-px alt'
              src={toAbsoluteUrl('/media/logos/logo-tumwhite.png')}
            />
          </a>
          <h3
            className='font-weigth-bolder text-center font-size-h4 font-size-h1-lg '
            style={{color: 'white'}}
          >
            <span className='mr-1'>{intl.formatMessage({id: 'LOGIN.WELCOME'})}</span>
            <br />
            <span className='mr-1'>{intl.formatMessage({id: 'LOGIN.WELCOME2'})}</span>
          </h3>
        </div>
        <div
          className='aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center'
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/login-visual-1.svg')}')`,
          }}
        ></div>
      </div>

      {/* contenedor der*/}
      <div className='d-flex  flex-row-fluid bg-white'>
        <div className='d-flex flex-column-fluid'>
          <div className='d-flex flex-column-fluid flex-center '>
            <form
              className='form fv-plugins-bootstrap fv-plugins-framework'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {/* begin::Heading */}
              <div className='pb-13 pt-5 '>
                <h3 className='font-weight-bolder text-dark font-size-h4 font-size-h1-lg'>
                  <span>{intl.formatMessage({id: 'LOGIN.TITTLE'})}</span>
                </h3>
              </div>

              {/* begin::Form group */}
              <div className='form-group fv-plugins-icon-container'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                  <span>
                    {intl.formatMessage({
                      id: 'LOGIN.USER',
                    })}
                  </span>
                </label>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className='form-group fv-plugins-icon-container'>
                {/* begin::Link */}

                <div className='d-flex justify-content-between mt-n5'>
                  <label className='form-label fw-bolder text-dark fs-6 mb-0 pt-10'>
                    {intl.formatMessage({id: 'LOGIN.PASSWORD'})}
                  </label>
                  <a
                    // eslint-disable-next-line no-script-url
                    href='javascript:;'
                    className='text-primary form-label fw-bolder text-dark fs-6 mb-0 pt-10 text-hover-primary '
                    id='kt_login_forgot'
                  >
                    <span style={{color: 'red'}}>
                      {intl.formatMessage({id: 'LOGIN.FORGOTPASS'})}
                    </span>
                  </a>
                </div>

                {/* end::Link */}

                <input
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
              {/* begin::Action */}
              <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
                <button
                  type='submit'
                  id='kt_signin_submit'
                  className='btn btn-primary btn-hover-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'LOGIN.BOTTON'})}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Cargando...
                      <span className='ml-3 spinner spinner-white'></span>
                    </span>
                  )}
                </button>
              </div>
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {isErrorLogin && (
                    <span role='alert' style={{color: 'red'}}>
                      {intl.formatMessage({id: 'AUTH.LOGIN.ERROR'})}
                    </span>
                  )}
                </div>
              </div>
              {/* end::Action */}
            </form>
          </div>
          <div className='d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0'>
            <div className='text-black font-size-lg font-weight-bolder mr-10'>
              <span className='mr-1'>{intl.formatMessage({id: 'LOGIN.YEAR'})}</span>
            </div>
            <a
              // eslint-disable-next-line no-script-url
              href='#'
              target='_blank'
              className='text-black '
            >
              <span style={{color: 'black'}}>{intl.formatMessage({id: 'LOGIN.HS'})}</span>
            </a>
            <a
              // eslint-disable-next-line no-script-url
              href='#'
              className='ml-5 font-weight-bolder font-size-lg'
            >
              <span style={{color: 'red'}}>{intl.formatMessage({id: 'LOGIN.CONTACT'})}</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
