import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useIntl} from 'react-intl'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'

export function MenuInner() {
  const intl = useIntl()
  const rol = useSelector<RootState>(({auth}) => auth.user?.rol, shallowEqual)

  const validateRole = (rol: any) => rol?.includes('admin') || rol?.includes('super-admin')
  const validateRoleSeller = (rol: any) => rol?.includes('seller')
  
  return (
    <>
      {validateRole(rol) ? (
        <>
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD1'})} to='/dashboard' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD5'})} to='/categorias' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD2'})} to='/tiendas' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD10'})} to='/sucursales' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD4'})} to='/productos' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD6'})} to='/pedidos' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD9'})} to='/ventas' />
          {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD7'})} to='/usuarios' /> */}
        </>
      ) : validateRoleSeller(rol) ? (
        <>
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD5'})} to='/categorias' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD10'})} to='/sucursales' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD4'})} to='/productos' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD6'})} to='/pedidos' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD9'})} to='/ventas' />
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD8'})} to='/repartidores' />
        </>
      ) : (<></>)}
     
        
      {validateRole('') && (
        <MenuInnerWithSub
          title={intl.formatMessage({id: 'MENU.DASHBOARD3'})}
          to='/crafted'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {/* PAGES */}

          <MenuInnerWithSub
            title='Pages'
            to='/crafted/pages'
            icon='/media/icons/duotune/general/gen022.svg'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuInnerWithSub
              title='Profile'
              to='/crafted/pages/profile'
              hasArrow={true}
              hasBullet={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
              <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
              <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
              <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
              <MenuItem
                to='/crafted/pages/profile/connections'
                title='Connections'
                hasBullet={true}
              />
            </MenuInnerWithSub>
            <MenuInnerWithSub
              title='Wizards'
              to='/crafted/pages/wizards'
              hasArrow={true}
              hasBullet={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem
                to='/crafted/pages/wizards/horizontal'
                title='Horizontal'
                hasBullet={true}
              />
              <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
            </MenuInnerWithSub>
          </MenuInnerWithSub>

          {/* ACCOUNT */}
          <MenuInnerWithSub
            title='Accounts'
            to='/crafted/accounts'
            icon='/media/icons/duotune/communication/com006.svg'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
          </MenuInnerWithSub>

          {/* ERRORS */}
          <MenuInnerWithSub
            title='Errors'
            to='/error'
            icon='/media/icons/duotune/technology/teh004.svg'
            hasArrow={true}
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
            <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
          </MenuInnerWithSub>

          {/* Widgets */}
          <MenuInnerWithSub
            title='Widgets'
            to='/crafted/widgets'
            icon='/media/icons/duotune/general/gen025.svg'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
            <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
            <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
            <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
            <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
            <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>
      )}
    </>
  )
}
