import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../setup'
export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const Productos = lazy(() => import('../modules/catalogos/products'))
  const Pedidos = lazy(() => import('../modules/catalogos/Orders'))
  const Usuarios = lazy(() => import('../modules/catalogos/Users'))
  const Repartidores = lazy(() => import('../modules/catalogos/UsersDelivery'))
  const Ventas = lazy(() => import('../modules/catalogos/Sales'))
  const Tiendas = lazy(() => import('../modules/catalogos/stores'))
  const Categorias = lazy(() => import('../modules/catalogos/categories'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const Sucursales = lazy(()=>import('../modules/catalogos/BranchOffice'))

  const rol = useSelector<RootState>(({auth}) => auth.user?.rol, shallowEqual)

  const validateRole = (rol: any) => rol?.includes('admin') || rol?.includes('super-admin')
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/categorias' component={Categorias} />
        <Route path='/productos' component={Productos} />
        <Route path='/pedidos' component={Pedidos} />
        <Route path='/usuarios' component={Usuarios} />
        <Route path='/repartidores' component={Repartidores} />
        <Route path='/ventas' component={Ventas} />
        <Route path='/tiendas' component={Tiendas} />
        <Route path='/sucursales' component={Sucursales} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to={validateRole(rol) ? '/dashboard' : 'categorias'} />
        <Redirect exact from='/' to={validateRole(rol) ? '/dashboard' : 'categorias'} />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
