/* eslint-disable jsx-a11y/anchor-is-valid */
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'


export function AuthPage() {
  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid '
        id='kt_login'
      >
        {/* bg-white */}
        <Switch>
          <Route path='/auth/login' component={Login} />
          <Route path='/auth/registration' component={Registration} />

          <Route path='/auth/forgot-password' component={ForgotPassword} />
          <Redirect from='/auth' exact={true} to='/auth/login' />
          <Redirect to='/auth/login' />
        </Switch>
      </div>
    </div>
  )
}
