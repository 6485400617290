/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

const StatisticsWidget9: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  return (
    <div className={`card ${className}`}>
      <div>
        <div className='card-body d-flex flex-column'>
          {/* begin::Header */}
          <div className='flex-grow-1 pb-2'>
            <div className='d-flex align-items-center pr-2 mb-6'>
              <span className='text-muted fw-bold fs-lg flex-grow-1'>
                {intl.formatMessage({id: 'STATISTICSWIDGET9.TITTLE'})}
              </span>
              <div className='symbol symbol-50'>
                <span className='symbol-label bg-light-light'>
                  <SVG src={toAbsoluteUrl('/media/logos/Fire.svg')} />
                </span>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}

            <td>
              <a href='#' className='text-dark fw-bolder text-hover-primary fs-3 mb-1'>
                {intl.formatMessage({id: 'STATISTICSWIDGET9.SUBTITTLE1'})}
                <br /> {intl.formatMessage({id: 'STATISTICSWIDGET9.SUBTITTLE2'})}
              </a>
            </td>

            <p className='text-muted font-weight-normal font-size-lg mt-6'>
              {intl.formatMessage({id: 'STATISTICSWIDGET9.DESCRIPCION1'})}
              <br />
              {intl.formatMessage({id: 'STATISTICSWIDGET9.DESCRIPCION2'})}
            </p>
          </div>
        </div>
        {/* end: Card Body */}
      </div>
    </div>
  )
}

export {StatisticsWidget9}
