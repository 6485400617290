/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useIntl} from 'react-intl'

type Props = {
  className: string
}

const ChartsWidget1: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* no reconoce el fondo radial degradado*/}
      <div className='card card-custom bg-warning gutter-b card-stretch'>
        <div className='card-header border-0 py-5'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {intl.formatMessage({id: 'CHARTSWIDGET1.TITTLE'})}
            </span>
          </h3>
          {/* end::Title */}

          {/* begin::Toolbar */}
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <div
              className='btn btn-sm btn-icon btn-active-light'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen023.svg' className='svg-icon-2' />
            </div>

            <Dropdown1 />
            {/* end::Menu */}
          </div>
          {/* end::Toolbar */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          {/* begin::Chart */}
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
          {/* end::Chart */}
        </div>
        <div className='card-spacer bg-white card-rounded flex-grow-1'>
          <div className='row me-2'>
            <div className='col px-8 py-6 mr-1'>
              <div className='font-size-sm text-muted font-weight-bol'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.SUBTITTLE1'})}</span>
              </div>
              <div className='text-dark fw-bolder fs-3 mb-1'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.PRICE1'})}</span>
              </div>
            </div>
            <div className='col px-8 py-6 mr-8'>
              <div className='font-size-sm text-muted font-weight-bol'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.SUBTITTLE2'})}</span>
              </div>
              <div className='text-dark fw-bolder fs-3 mb-1'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.PRICE2'})}</span>
              </div>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col px-8 py-6 mr-8'>
              <div className='font-size-sm text-muted font-weight-bol'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.SUBTITTLE3'})}</span>
              </div>
              <div className='text-dark fw-bolder fs-3 mb-1'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.PRICE3'})}</span>
              </div>
            </div>
            <div className='col px-8 py-6 mr-8'>
              <div className='font-size-sm text-muted font-weight-bol'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.SUBTITTLE4'})}</span>
              </div>
              <div className='text-dark fw-bolder fs-3 mb-1'>
                <span>{intl.formatMessage({id: 'CHARTSWIDGET1.PRICE4'})}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Header */}

      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget1}

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 58],
      },
      {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 105],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },

    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
