/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget12,
  ListsWidget5,
} from '../../../_metronic/partials/widgets'
import {useLayout} from '../../../_metronic/layout/core'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget12
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-6'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {setLayout} = useLayout()
  const [updatedLayout, setUpdatedLayout] = useState(false)

  useEffect(() => {
    if (!updatedLayout) {
      setLayout({isAuthPage: false})
      setUpdatedLayout(true)
    }
  }, [setLayout, updatedLayout, setUpdatedLayout])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD1'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
